@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: "Roboto", sans-serif;
  }
}
html {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: rgba(209, 213, 219, 0.2);
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
}
.banner-swiper > .swiper-pagination {
  position: static;
}
.product-swiper > .swiper-pagination {
  bottom: -3px !important;
}
/* react-data-table */
.hsaRcN {
  fill: rgb(0, 0, 0) !important;
  transition-duration: 300ms !important;
}
.hsaRcN:hover {
  background-color: #c0bfbf !important;
  fill: white !important;
}
.bqldxW div {
  font-size: 16px !important;
}
